import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Alert } from 'react-bootstrap';
import client from '../config/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './Anfahrt.css';

// Fallback-Daten, falls keine Contentful-Daten verfügbar sind
const fallbackStandorte = [
  {
    sys: { id: '1' },
    fields: {
      titel: 'Sportplatz Jägersfreude',
      adressezeile1: 'TuS 1888 Jägersfreude e.V.',
      adressezeile2: 'Am Sportplatz 1',
      adressezeile3: '',
      postleitzahl: '66123',
      ort: 'Saarbrücken',
      nutzung: 'Fußballtraining und -spiele, Sommerfeste, Outdoor-Veranstaltungen',
      latitude: 49.2728,
      longitude: 7.0253
    }
  },
  {
    sys: { id: '2' },
    fields: {
      titel: 'Vereinsheim mit Sporthalle',
      adressezeile1: 'Waldweg 45',
      adressezeile2: '',
      adressezeile3: '',
      postleitzahl: '66123',
      ort: 'Saarbrücken',
      nutzung: 'Handball, Tischtennis, Kinderturnen, Zumba, Bodyforming, Muay Thai, Seniorensport, Vereinsbüro',
      latitude: 49.2725,
      longitude: 7.0250
    }
  }
];

const Anfahrt = () => {
  const [standorte, setStandorte] = useState([]);
  const [error, setError] = useState(null);
  const [showHallenplan, setShowHallenplan] = useState(false);

  useEffect(() => {
    const fetchStandorte = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'unsereStandorte'  // Angepasst an den Content-Type Namen in Contentful
        });
        
        console.log('Contentful Response:', response); // Debug-Log
        
        if (response.items.length === 0) {
          console.log('Keine Daten in Contentful, verwende Fallback-Daten');
          setStandorte(fallbackStandorte);
        } else {
          setStandorte(response.items);
        }
      } catch (error) {
        console.error('Error fetching standorte:', error);
        setError('Fehler beim Laden der Standorte. Fallback-Daten werden angezeigt.');
        setStandorte(fallbackStandorte);
      }
    };

    fetchStandorte();
  }, []);

  // Rendere einen einzelnen Standort
  const renderStandort = (standort) => {
    console.log('Standort Daten:', standort.fields); // Debug-Log
    return (
    <Col md={6} className="mb-4" key={standort.sys.id}>
      <Card className="h-100 shadow-sm">
        <Card.Header as="h4">{standort.fields.titel}</Card.Header>
        <Card.Body>
          {/* Adresse */}
          <p className="text-start">
            <strong>Adresse:</strong><br />
            {standort.fields.adressezeile1}<br />
            {standort.fields.adressezeile2 && (
              <>{standort.fields.adressezeile2}<br /></>
            )}
            {standort.fields.adressezeile3 && (
              <>{standort.fields.adressezeile3}<br /></>
            )}
            {standort.fields.postleitzahl} {standort.fields.ort}
          </p>

          {/* Kontaktinformationen */}
          {standort.fields.telefon && (
            <p>
              <strong>Telefon:</strong> {standort.fields.telefon}
            </p>
          )}
          {standort.fields.email && (
            <p>
              <strong>E-Mail:</strong> {standort.fields.email}
            </p>
          )}

          {/* Öffnungszeiten */}
          {standort.fields.oeffnungszeiten && (
            <p>
              <strong>Öffnungszeiten:</strong><br />
              {standort.fields.oeffnungszeiten}
            </p>
          )}

          {/* Nutzungsinformationen */}
          {standort.fields.nutzung && (
            <p>
              <strong>Nutzung:</strong><br />
              {standort.fields.nutzung}
            </p>
          )}
          {standort.fields.beschreibungDerNutzung && (
            <p>
              <strong>Beschreibung:</strong><br />
              {documentToReactComponents(standort.fields.beschreibungDerNutzung)}
            </p>
          )}

          {/* Zusätzliche Informationen */}
          {standort.fields.anfahrt && (
            <p>
              <strong>Anfahrt:</strong><br />
              {standort.fields.anfahrt}
            </p>
          )}
          {standort.fields.parkplaetze && (
            <p>
              <strong>Parkplätze:</strong><br />
              {standort.fields.parkplaetze}
            </p>
          )}
          {standort.fields.zusatzinformationen && (
            <p>
              <strong>Zusatzinformationen:</strong><br />
              {standort.fields.zusatzinformationen}
            </p>
          )}

          {/* Google Maps */}
          {(standort.fields.latitude && standort.fields.longitude) ? (
            <div className="map-container">
              <iframe 
                src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1000!2d${standort.fields.longitude}!3d${standort.fields.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0`}
                className="map-frame"
                style={{
                  width: "100%",
                  height: "300px",
                  border: "0",
                  borderRadius: "4px"
                }}
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title={`Karte ${standort.fields.titel}`}
              ></iframe>
            </div>
          ) : (
            <p className="text-muted">
              <em>Keine Kartenansicht verfügbar (Koordinaten: {standort.fields.latitude}, {standort.fields.longitude})</em>
            </p>
          )}
        </Card.Body>
      </Card>
    </Col>
  )};

  return (
    <div style={{ marginTop: '-12px' }}>
      <Container>
        {error && (
          <Alert variant="warning">{error}</Alert>
        )}
        
        <section className="mb-5">
          <h3 className="page-heading mb-4">Unsere Standorte</h3>
          <Row>
            {standorte.map(renderStandort)}
          </Row>
        </section>

        {/* Hallenplan Modal */}
        <Modal
          show={showHallenplan}
          onHide={() => setShowHallenplan(false)}
          dialogClassName="hallenplan-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Hallenplan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src="/images/hallenplan.jpg"
              alt="Hallenplan"
              className="hallenplan-image"
            />
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default Anfahrt;
