/**
 * Sponsors Component
 */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useConfig } from '../config';
import { createClient } from 'contentful';
import './Sponsors.css';

const Sponsors = () => {
  const [sponsors, setSponsors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const config = useConfig();

  useEffect(() => {
    const loadSponsors = async () => {
      if (!config) {
        console.log("Warte auf Konfiguration...");
        return;
      }

      if (!config.contentful?.spaceId || !config.contentful?.accessToken) {
        console.log("Contentful-Konfiguration noch nicht verfügbar");
        return;
      }

      console.log("Sponsoren: Lade Daten von Contentful");
      const client = createClient({
        space: config.contentful.spaceId,
        accessToken: config.contentful.accessToken,
        environment: config.contentful.environment || 'master'
      });

      try {
        const response = await client.getEntries({
          content_type: 'sponsoren',
          order: 'fields.name'
        });

        console.log(`Sponsoren: ${response.items.length} Einträge gefunden`);
        
        // Detaillierte Logo-URL-Prüfung
        response.items.forEach(sponsor => {
          const logoUrl = sponsor.fields.logo?.fields?.file?.url;
          console.log(`Sponsor "${sponsor.fields.name}":`);
          console.log(`- Logo URL: ${logoUrl || 'Keine URL'}`);
          if (logoUrl) {
            // Stelle sicher, dass die URL mit https:// beginnt
            if (!logoUrl.startsWith('http')) {
              sponsor.fields.logo.fields.file.url = `https:${logoUrl}`;
              console.log(`- Korrigierte URL: ${sponsor.fields.logo.fields.file.url}`);
            }
          }
        });
        
        setSponsors(response.items);
        setError(null);
      } catch (err) {
        console.error("Fehler beim Laden der Sponsoren:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    loadSponsors();
  }, [config]);

  // Debug-Render-Info
  console.log("Sponsors Render:", {
    loading,
    error: error?.message,
    sponsorsCount: sponsors.length,
    sponsorIds: sponsors.map(s => s.sys.id)
  });

  if (loading) {
    console.log("Sponsors: Zeige Ladezustand");
    return (
      <div className="sponsors-section">
        <Container>
          <h2 className="text-center mb-4">Unsere Sponsoren</h2>
          <p className="text-center">Sponsoren werden geladen...</p>
        </Container>
      </div>
    );
  }

  if (error) {
    console.error("Sponsors: Fehler beim Rendern:", error);
    return null;
  }

  if (!sponsors || sponsors.length === 0) {
    console.log("Sponsors: Keine Sponsoren zum Anzeigen");
    return null;
  }

  console.log("Sponsors: Rendere", sponsors.length, "Sponsoren");
  return (
    <div className="sponsors-section">
      <Container>
        <h2 className="text-center mb-4">Unsere Sponsoren</h2>
        <Row className="justify-content-center">
          {sponsors.map(sponsor => {
            const logoUrl = sponsor.fields.logo?.fields?.file?.url;
            // Prüfe die URL nochmal beim Rendern
            console.log(`Rendere Logo für ${sponsor.fields.name}:`, logoUrl);
            
            return (
              <Col key={sponsor.sys.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
                <div className="sponsor-card">
                  {logoUrl && (
                    sponsor.fields.link ? (
                      <a 
                        href={sponsor.fields.link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="sponsor-link"
                      >
                        <img
                          src={logoUrl}
                          alt={sponsor.fields.name}
                          className="sponsor-logo"
                          crossOrigin="anonymous"
                          loading="lazy"
                          onError={(e) => {
                            console.error(`Fehler beim Laden des Logos für ${sponsor.fields.name}:`, e);
                            e.target.style.display = 'none';
                          }}
                        />
                      </a>
                    ) : (
                      <img
                        src={logoUrl}
                        alt={sponsor.fields.name}
                        className="sponsor-logo"
                        crossOrigin="anonymous"
                        loading="lazy"
                        onError={(e) => {
                          console.error(`Fehler beim Laden des Logos für ${sponsor.fields.name}:`, e);
                          e.target.style.display = 'none';
                        }}
                      />
                    )
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Sponsors;
