import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useConfig } from '../config';
import './Navbar.css';

// Standard-Menüeinträge als Fallback
const defaultMenuItems = [
  { id: 'home', label: 'Home', visible: true },
  { id: 'news', label: 'Aktuelles', visible: true },
  { id: 'calendar', label: 'Kalender', visible: true },
  { id: 'about', label: 'Über uns', visible: true },
  { id: 'contact', label: 'Kontakt', visible: true },
  { id: 'pictures', label: 'Bilder', visible: true },
  { id: 'links', label: 'Downloads', visible: true },
  { id: 'imprint', label: 'Impressum', visible: true },
  { id: 'privacy', label: 'Datenschutz', visible: true }
];

// Mapping von IDs zu Pfaden
const pathMap = {
  home: '/',
  news: '/news',
  calendar: '/kalender',
  about: '/verein',
  contact: '/kontakt',
  pictures: '/bilder',
  links: '/downloads',
  imprint: '/impressum',
  privacy: '/datenschutz',
  directions: '/anfahrt'
};

const Navbar = () => {
  const location = useLocation();
  const config = useConfig();
  
  // Verwende die konfigurierten Menüeinträge oder die Standardeinträge
  // Filtere nur die sichtbaren Einträge, behalte aber die ursprüngliche Reihenfolge bei
  const visibleMenuItems = (config?.menu?.items || defaultMenuItems)
    .filter(item => item.visible);

  return (
    <BootstrapNavbar expand="lg" className="navbar">
      <Container>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav">
          <GiHamburgerMenu size={24} className="burger-icon" />
        </BootstrapNavbar.Toggle>
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto text-center">
            {visibleMenuItems.map(item => (
              <Nav.Link
                key={item.id}
                as={Link}
                to={pathMap[item.id]}
                className={location.pathname === pathMap[item.id] ? 'active' : ''}
                style={{ fontSize: item.fontSize }}
              >
                {item.label}
              </Nav.Link>
            ))}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
