import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import de from 'date-fns/locale/de';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Container } from 'react-bootstrap';
import { useConfig } from '../config';
import { createClient } from 'contentful';
import './Kalender.css';

/**
 * Kalender Component - Vereinskalender
 * 
 * Diese Komponente zeigt einen interaktiven Kalender für Vereinstermine.
 * Verfügbare Ansichten: Monat, Woche, Tag und Agenda
 * Die Termine werden aus Contentful geladen und im Kalender angezeigt.
 */

// Lokalisierung für den Kalender
const locales = {
  'de-DE': de,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// Deutsche Übersetzungen für den Kalender
const messages = {
  allDay: 'Ganztägig',
  previous: 'Zurück',
  next: 'Vor',
  today: 'Heute',
  month: 'Monat',
  week: 'Woche',
  day: 'Tag',
  agenda: 'Agenda',
  date: 'Datum',
  time: 'Uhrzeit',
  event: 'Termin',
  noEventsInRange: 'Keine Termine in diesem Zeitraum.',
  showMore: total => `+ ${total} weitere`,
};

const Kalender = () => {
  const [events, setEvents] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const config = useConfig();

  useEffect(() => {
    if (config?.contentful?.spaceId && config?.contentful?.accessToken) {
      const client = createClient({
        space: config.contentful.spaceId,
        accessToken: config.contentful.accessToken,
      });

      // Lade Kalender
      client.getEntries({
        content_type: 'kalender',
      })
      .then((response) => {
        const calendarData = response.items.map(item => ({
          id: item.sys.id,
          kalendername: item.fields.kalendername,
          kalenderfarbe: item.fields.kalenderfarbe
        }));
        setCalendars(calendarData);
      })
      .catch(console.error);

      // Lade Veranstaltungen
      client.getEntries({
        content_type: 'veranstaltungen',
        order: 'fields.startDatum'
      })
      .then(response => {
        const eventData = response.items
          .filter(item => {
            const start = new Date(item.fields.startDatum);
            const end = new Date(item.fields.endDatum);
            const isValid = !isNaN(start) && !isNaN(end) && end >= start;
            
            if (!isValid) {
              console.warn(
                `⚠️ TERMINWARNUNG für "${item.fields.titel}":`,
                `\n- Start: ${new Date(item.fields.startDatum).toLocaleString('de-DE')}`,
                `\n- Ende: ${new Date(item.fields.endDatum).toLocaleString('de-DE')}`,
                `\n\nProblem:`,
                end < start ? 
                  `\n❌ Das Enddatum liegt VOR dem Startdatum! Bitte in Contentful korrigieren.` :
                  `\n❌ Ungültiges Datum gefunden. Bitte Datumswerte in Contentful überprüfen.`,
                `\n\nTipp: Termine müssen ein gültiges Start- UND Enddatum haben, und das Ende muss nach dem Start liegen.`
              );
            }
            
            return isValid;
          })
          .map(item => ({
            id: item.sys.id,
            title: item.fields.titel,
            start: new Date(item.fields.startDatum),
            end: new Date(item.fields.endDatum),
            allDay: item.fields.ganztagig,
            description: item.fields.beschreibung,
            location: item.fields.ort,
            color: item.fields.farbe
          }));

        setEvents(eventData);
      })
      .catch(error => console.error('Fehler:', error));
    }
  }, [config?.contentful?.spaceId, config?.contentful?.accessToken]);

  // Event-Styling basierend auf der Farbe aus Contentful
  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: event.color,
        borderRadius: '4px',
        opacity: 0.8,
        color: 'white',
        border: 'none',
        display: 'block'
      }
    };
  };

  return (
    <div style={{ marginTop: '-43px' }}>
      <Container className="kalender-container">
        <h1 className="page-heading text-center mb-4">Vereinskalender</h1>
        
        <div className="calendar-wrapper">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 700 }}
            messages={messages}
            culture="de-DE"
            defaultView="month"
            views={['month', 'week', 'day', 'agenda']}
            eventPropGetter={eventStyleGetter}
            tooltipAccessor={event => `${event.description}${event.location ? ` | Ort: ${event.location}` : ''}`}
            min={new Date(1972, 0, 1, 7, 0, 0)}
            max={new Date(1972, 0, 1, 22, 0, 0)}
          />

          {/* Kalenderlegende am unteren Rand */}
          <div className="calendar-legend mt-3">
            <div className="d-flex align-items-center gap-2" style={{ marginLeft: '1rem' }}>
              <span style={{ fontSize: '0.95rem', color: '#666', fontWeight: '500' }}>Kalender:</span>
              <div className="d-flex flex-wrap gap-2">
                {calendars.map(calendar => (
                  <div key={calendar.id} className="d-flex align-items-center">
                    <div 
                      className="legend-label"
                      style={{ 
                        backgroundColor: calendar.kalenderfarbe,
                        color: 'white',
                        padding: '0.1rem 0.35rem',
                        borderRadius: '0.2rem',
                        fontSize: '0.8rem'
                      }}
                    >
                      {calendar.kalendername}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Kalender;
